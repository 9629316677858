<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, watch } from "vue";
import { debtorWorkTypesStore } from "@/helpers/common-store/store";
import { useDebtorLog } from "../../state/modules/debtorCardLogs";
import { storeToRefs } from "pinia";
import { DxForm, DxButtonItem, DxButtonOptions, DxSimpleItem, DxLabel, DxRequiredRule } from "devextreme-vue/form";
import { formatDate } from "devextreme/localization";

const store = useDebtorLog();
const { error } = storeToRefs(store);

const props = defineProps({
  debtorId: {
    required: true,
  },
  showPopup: {
    type: Boolean,
    default: false,
  },
  item: Object
});

const emits = defineEmits(["on-saved", "on-cancel"]);
const classes = computed(() => [error ? "alert-danger" : "alert-success"]);
const form = ref();

const now = computed(() => formatDate(new Date(), "yyyy-MM-ddTHH:mm:ss"));
const typesOptions = ref({
  dataSource: debtorWorkTypesStore,
  valueExpr: "id",
  displayExpr: "name",
});

const formData = ref({
  id: 0,
  date: now.value,
  text: "",
  type: 1,
});

watch(() => props.item, () => {
  if(props.item) {
    formData.value.id = props.item.id;
    formData.value.date = props.item.createdAt;
    formData.value.type = props.item.logTypeId;
    formData.value.text = props.item.text;
  }
})

onMounted(() => {
  formData.value.debtorId = props.debtorId;
  console.log(1);
});

async function save(e) {
  e.preventDefault();

  emits("on-saved", formData.value);
  form.value.instance.resetValues();
  formData.value.date = now.value;
  formData.value.type = 1;
}
function cancel() {
  emits("on-cancel");
  form.value.instance.resetValues();
  formData.value.date = now.value;
  formData.value.type = 1;
}
</script>

<template>
  <div>
    <DxPopupModal
      id="createDebtorWork"
      :visible="showPopup"
      :drag-enabled="true"
      :show-title="true"
      :width="800"
      title="Добавление работы"
      height="auto"
      @hidden="cancel"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="alert" :class="[classes]" v-if="error && error.length != 0">
        {{ error }}
      </div>

      <form @submit="save">
        <DxForm :form-data="formData" ref="form" :col-count="2">
          <DxSimpleItem data-field="id" :visible="false" />
          <DxSimpleItem
            data-field="date"
            editor-type="dxDateBox"
            :editor-options="{ type: 'datetime', dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss' }"
          >
            <DxLabel text="Дата работы" />
          </DxSimpleItem>
          <DxSimpleItem :editor-options="typesOptions" data-field="type" editor-type="dxSelectBox">
            <DxLabel text="Вид работы" />
            <DxRequiredRule />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="text"
            editor-type="dxTextArea"
            :is-required="true"
            :editor-options="{ showClearButton: true, inputAttr: { autocomplete: 'new-user-inhabitant-name' } }"
            :col-span="2"
          >
            <DxLabel text="Описание работы" />
            <DxRequiredRule />
          </DxSimpleItem>

          <DxButtonItem horizontal-alignment="left">
            <DxButtonOptions text="Отменить" type="danger" :on-click="cancel" />
          </DxButtonItem>
          <DxButtonItem horizontal-alignment="right">
            <DxButtonOptions text="Сохранить" type="success" :useSubmitBehavior="true" />
          </DxButtonItem>
        </DxForm>
      </form>
    </DxPopupModal>
  </div>
</template>
