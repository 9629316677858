<script setup>
import { ref, computed, defineExpose, onMounted, defineProps } from "vue";
import { storeToRefs } from "pinia";
import { useDebtorLog } from "../../state/modules/debtorCardLogs";
import { confirm } from "devextreme/ui/dialog";
import store from "@/state/store";

import TimelineItem from "./_timelineItem.vue";
import CreateDebtorWorkModal from "../../components/forms/create-debtor-work.vue";

const hasRightToAddEdit = computed(() => store.getters[`auth/hasRightAddEditDebtorWorkLogs`]);

const debtorLogStore = useDebtorLog();
const { rows: data } = storeToRefs(debtorLogStore);
const dataGrouped = computed(() => (data.value && data.value.length != 0 ? data.value : [{ year: new Date().getFullYear(), items: [] }]));

const props = defineProps(["debtorId"]);

const fromDate = new Date();
fromDate.setMonth(fromDate.getMonth() - 6);
const from = ref(fromDate.toDateString());
const to = ref(new Date().toDateString());
const search = ref("");
const personalWorkOnly = ref(false);

let timer;
function debounce(fn, wait) {
  if (timer) {
    clearTimeout(timer); // clear any pre-existing timer
  }
  const context = this; // get the current context
  timer = setTimeout(() => {
    fn.apply(context); // call the function if time expires
  }, wait || 500);
}

function onInput(e) {
  debounce(() => {
    search.value = e.event.target.value;
    load();
  }, 400);
}

onMounted(() => {
  load();
});

function repaint() {
  //
}
defineExpose({ repaint });

function load() {
  debtorLogStore.load(props.debtorId, {
    from: from.value,
    to: to.value,
    search: search.value,
    personalWorkOnly: personalWorkOnly.value,
  });
}

const showPopup = ref(false);

const currentEditing = ref();

async function onSaved(item) {
  if (currentEditing.value) {
    await debtorLogStore.update(props.debtorId, item);
  } else {
    await debtorLogStore.add(props.debtorId, item);
  }

  showPopup.value = false;
  load();
  //
  currentEditing.value = null;
}
function onCancel() {
  showPopup.value = false;
}
function addWorkClick() {
  currentEditing.value = null;
  showPopup.value = true;
}
async function editWorkClick(item) {
  currentEditing.value = item;
  console.log(currentEditing.value);
  showPopup.value = true;
}
function deleteWorkClick(item) {
  let result = confirm("<span class='fs-5 align-middle'>Вы уверены ?</span>", "Удаление работы");
  result.then(async (dialogResult) => {
    if (dialogResult) {
      await debtorLogStore.delete(props.debtorId, item.id);
      load();
    }
  });
}
</script>

<template>
  <div>
    <div class="d-flex gap-2 align-items-center">
      <DxDateBox
        v-model="from"
        :show-clear-button="true"
        :use-mask-behavior="true"
        placeholder="введите или выберите"
        @update:model-value="load"
      />
      -
      <DxDateBox
        v-model="to"
        :show-clear-button="true"
        :use-mask-behavior="true"
        placeholder="введите или выберите"
        @update:model-value="load"
      />
      <DxTextBox width="500" placeholder="Введите текст для поиска..." :show-clear-button="true" :value="search" @input="onInput" />
    </div>
    <div class="mt-2">
      <DxCheckBox v-model:value="personalWorkOnly" text="Отображать только персональную работу" @update:value="load" />
    </div>

    <div class="d-flex justify-content-end">
      <b-button variant="success" class="btn-label waves-effect waves-light my-2" @click="addWorkClick" v-if="hasRightToAddEdit">
        <i class="bx bx-plus label-icon align-middle fs-20 me-2"></i>
        Добавить запись о работе
      </b-button>
    </div>

    <div class="timeline-2 my-2 mb-4">
      <TimelineItem :item="d" v-for="d in dataGrouped" :key="d.year" @edit-click="editWorkClick" @delete-click="deleteWorkClick" />
    </div>

    <CreateDebtorWorkModal :debtorId="debtorId" :item="currentEditing" :show-popup="showPopup" @on-saved="onSaved" @on-cancel="onCancel" />
  </div>
</template>

<style>
.timeline-2::after,
.timeline-year {
  --vz-card-bg: #ececec;
}
.timeline-year p {
  color: #2e2e2e;
}
.timeline-2::after {
  height: 90%;
}
.timeline-2 .timeline-box {
  max-width: none;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
